//@flow
import gql from 'graphql-tag';

const mobile_phishing_discovered_phishing_apps_stats = gql`
  query MobilePhishingDiscoveredPhishingAppsStats {
    mobile_phishing_discovered_phishing_apps_stats {
      apps_taken_down_following_data_theorem_intervention_count
      apps_with_take_down_initiated_count
      discovered_phishing_apps_count
      discovered_phishing_apps_ignored_count
      discovered_phishing_apps_removed_count
      discovered_phishing_brands_count
      legitimate_apps_with_phishing_version_count
      onboarded_legitimate_apps_count
    }
  }
`;

const full_subscription_inquiry = gql`
  mutation FullSubscriptionInquiry(
    $body: FullSubscriptionInquiryBody!
    $id: ID!
  ) {
    full_subscription_inquiry(body: $body, id: $id) {
      nothing
    }
  }
`;

const unlock_data_report = gql`
  mutation UnlockDataReport($body: UnlockDataReportBody!, $id: ID!) {
    unlock_data_report(body: $body, id: $id) {
      nothing
    }
  }
`;

const mobile_phishing_post_comment = gql`
  mutation MobilePhishingPostcomment(
    $body: MobilePhishingPostComment!
    $id: ID!
  ) {
    mobile_phishing_post_comment(body: $body, id: $id) {
      uuid
    }
  }
`;

const triage_discovered_app = gql`
  mutation TriageDiscoveredApp($id: ID!, $decision: String!) {
    triage_discovered_app(id: $id, triaging_decision: $decision) {
      nothing
    }
  }
`;

const discovered_apps_search_filter_values = gql`
  query DiscoveredAppsSearchFilterValues {
    discovered_apps_search_filter_values {
      discovered_apps_search_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;

const mobile_phishing_discovered_phishing_apps = gql`
  query MobilePhishingDiscoveredPhishingApps(
    $filter_by_discovered_application_keyword: [String]
    $filter_by_infringement_level: [String]
    $filter_by_discovered_app_status: [String]
    $filter_by_triaging_decision: [String]
    $order_by: String
    $cursor: String
  ) {
    mobile_phishing_discovered_phishing_apps(
      filter_by_discovered_application_keyword: $filter_by_discovered_application_keyword
      filter_by_infringement_level: $filter_by_infringement_level
      filter_by_discovered_app_status: $filter_by_discovered_app_status
      filter_by_triaging_decision: $filter_by_triaging_decision
      order_by: $order_by
      cursor: $cursor
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      discovered_phishing_apps {
        canonical_id
        date_seen_first
        date_seen_last
        description
        discovered_app_status
        icon_url
        impact_rating
        impact_rating_explanation
        infringement_level
        matching_keywords
        name
        platform
        publisher_name
        screenshot_urls
        triaging_decision
        url
        minimum_downloads
        uuid
        store {
          icon_url
          name
          popularity
          presence
        }
        cloned_legitimate_app {
          bundle_id
          last_5_visual_identities {
            date_seen_first
            date_seen_last
            icon_url
            name
            name_detection_enabled
          }
          platform
          store {
            icon_url
            name
            popularity
            presence
          }
          url
          uuid
        }
      }
    }
  }
`;

const mobile_phishing_legitimate_apps = gql`
  query MobilePhishingLegitimateApps($cursor: String) {
    mobile_phishing_legitimate_apps(cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      legitimate_apps {
        bundle_id
        last_5_visual_identities {
          date_seen_first
          date_seen_last
          icon_url
          name
          name_detection_enabled
        }
        platform
        store {
          icon_url
          name
          popularity
          presence
        }
        url
        uuid
      }
    }
  }
`;

const mobile_phishing_discovered_phishing_apps_events = gql`
  query MobilePhishingDiscoveredPhishingAppsEvents(
    $uuid: ID!
    $cursor: String
  ) {
    mobile_phishing_discovered_phishing_apps_events(
      uuid: $uuid
      cursor: $cursor
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      events {
        date_created
        discovered_app_uuid
        type
      }
    }
  }
`;

const mobile_phishing_discovered_phishing_apps_comments = gql`
  query MobilePhishingDiscoveredPhishingAppsComments(
    $uuid: ID!
    $cursor: String
  ) {
    mobile_phishing_discovered_phishing_apps_comments(
      uuid: $uuid
      cursor: $cursor
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      comments {
        content
        date_created
        uuid
        user {
          date_created
          first_name
          last_name
          login_email
          notification_email
          uuid
        }
      }
    }
  }
`;

export default {
  unlock_data_report,
  triage_discovered_app,
  full_subscription_inquiry,
  mobile_phishing_discovered_phishing_apps,
  mobile_phishing_discovered_phishing_apps_events,
  mobile_phishing_discovered_phishing_apps_comments,
  mobile_phishing_post_comment,
  mobile_phishing_discovered_phishing_apps_stats,
  mobile_phishing_legitimate_apps,
  discovered_apps_search_filter_values,
};
