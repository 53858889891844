import React from 'react';
import Box from '@material-ui/core/Box';
var PageContainer = function (_a) {
    var children = _a.children;
    return (React.createElement(Box, { style: {
            backgroundColor: '#F4F5FC',
            padding: '0 24px',
            minHeight: '100vh',
        } }, children));
};
export default PageContainer;
